<template>
  <BaseFormCard
    heading="Update Member"
    submit-title="Update"
    @submit="updateMember"
  >
    <BaseInput
      v-model="member.usr.name"
      title="Name"
      name="usr_name"
      type="text"
      placeholder=""
      readonly
    />
    <BaseInputCheckbox
      v-model="member.is_manager"
      title="Is Manager"
      name="is_manager"
    />
    <BaseSelect
      v-if="roles.length > 0"
      v-model="member.roles"
      :options="roles"
      title="Roles"
      name="roles"
      multiple
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to edit a team.
 * Allows editing of members and managers.
 */
import FieldError from '@/components/FieldError'
import { mapActions } from 'vuex'
import teamAPI from '@/api/team'
import userAPI from '@/api/user'

export default {
  components: {
    FieldError,
  },
  props: {
    teamId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      member: {
        usr: { name: '' },
        is_manager: false,
        roles: [],
      },
      roles: [],
      error: '',
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      Promise.all([
        this.getTeam(this.teamId),
        teamAPI.getTeamMember(this.teamId, this.id),
        userAPI.getRoles(),
      ]).then(([team, member, roles]) => {
        this.member = member.data
        this.roles = roles.data.filter(r => r.is_team_role).map(r => r.project_type_name + ' : ' + r.name)
      }).catch(error => {
        this.error = error.message
      })
    },
    updateMember () {
      teamAPI.updateTeamMember(this.member).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    ...mapActions('user', ['getTeam']),
  },
}
</script>
