<template>
  <ListTemplate
    :fields="fields"
    :api-url="holidaysURL"
    title="Public Holidays"
  />
</template>

<script>
/**
 * View to list all Users
 */
import ListTemplate from '@/components/ListTemplate'
import holidayAPI from '@/api/holiday'

export default {
  components: {
    ListTemplate,
  },
  data () {
    return {
      fields: [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'date',
          title: 'Date',
          sortField: 'date',
        },
        {
          name: 'descr',
          title: 'Description',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    holidaysURL () {
      return holidayAPI.holidaysURL()
    },
  },
}
</script>
