<template>
  <BaseFormCard
    :heading="`${submitName} Survey Question`"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseInput
      v-model="question.name"
      title="Name"
      name="name"
      type="text"
      trim
    />
    <BaseInput
      v-model="question.rating"
      title="Rating Question"
      name="rating"
      type="text"
      required
      trim
    />
    <BaseInput
      v-model="question.detail"
      title="Feedback/Detail Question"
      name="detail"
      type="text"
      trim
    />
    <BaseInputNumber
      v-model.number="question.ord"
      title="Order"
      name="ord"
      :min="1"
    />
    <BaseInputCheckbox
      v-model="question.disabled"
      title="Disabled"
      name="disabled"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
import FieldError from '@/components/FieldError'
import { mapActions } from 'vuex'
import teamAPI from '@/api/team'

export default {
  components: {
    FieldError,
  },
  props: {
    teamId: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      team: {
        name: '',
      },
      question: {
        name: '',
        ord: null,
        rating: '',
        detail: '',
        disabled: false,
      },
      loading: false,
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create'
      }
      return 'Update'
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      const promises = [this.getTeam(this.teamId)]
      this.loading = true
      if (this.id) {
        promises.push(teamAPI.getSurveyQuestion(this.teamId, this.id))
      }
      Promise.all(promises).then(([team, question]) => {
        this.team = team
        this.loading = false
        if (this.id) {
          this.question = question.data
        }
      }).catch(error => {
        this.loading = false
        this.error = error.message
      })
    },
    submit () {
      if (this.id) {
        teamAPI.updateSurveyQuestion(this.question).then(resp => {
          this.$router.go(-1)
        }).catch(error => {
          this.error = error.message
        })
      } else {
        teamAPI.createSurveyQuestion(this.teamId, this.question).then(resp => {
          this.$router.go(-1)
        }).catch(error => {
          this.error = error.message
        })
      }
    },
    ...mapActions('user', ['getTeam']),
  },
}
</script>
