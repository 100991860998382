<template>
  <BaseFormCard
    :heading="submitName"
    :submit-title="submitName"
    :submit-promise="submit"
  >
    <BaseInput
      v-model="user.name"
      :maxlength="64"
      title="Full Name"
      name="name"
      type="text"
      placeholder="Full Name"
      required
      trim
    />
    <BaseInput
      v-model="user.email"
      :maxlength="64"
      title="E-Mail"
      name="email"
      type="email"
      placeholder="E-Mail"
      required
      trim
    />
    <BaseInputPassword
      v-model="user.password"
      title="Password"
      name="password"
      placeholder="Password"
      confirm
    />
    <BaseInput
      v-model="user.ad_account"
      :maxlength="32"
      title="AD Account"
      name="adaccount"
      type="text"
      placeholder="AD Account"
      required
      trim
    />
    <BaseInputCheckbox
      v-model="user.is_admin"
      title="Is Admin"
      name="isadmin"
    />
    <BaseInputCheckbox
      v-model="user.forcePasswordChange"
      title="Force Password Change"
      name="forcePasswordChange"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to create or update a User.
 */
import FieldError from '@/components/FieldError'
import userAPI from '@/api/user'
import { mapActions } from 'vuex'

export default {
  components: {
    FieldError,
  },
  props: {
    /**
     * User to edit. If not provided, create a new user.
     */
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        ad_account: '',
        is_admin: false,
        forcePasswordChange: false,
      },
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create User'
      }
      return 'Update User'
    },
  },
  beforeMount () {
    if (this.id) {
      this.getUser(this.id).then(user => {
        this.user.id = user.id
        this.user.name = user.name
        this.user.email = user.email
        this.user.ad_account = user.ad_account
        this.user.is_admin = user.is_admin
        this.user.forcePasswordChange = user.forcePasswordChange
      }).catch(error => {
        this.error = error.message
      })
    }
  },
  methods: {
    async submit () {
      if (this.id) {
        await userAPI.updateUser(this.user)
      } else {
        await userAPI.createUser(this.user)
      }
      this.$router.go(-1)
    },
    ...mapActions('user', ['getUser']),
  },
}
</script>
