<template>
  <div>
    <h3>User: {{ user.name }}</h3>
    <div class="row">
      <div class="col-md-6">
        <BaseCard>
          <table class="info-table">
            <tr>
              <td>Name</td>
              <td>{{ user.name }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ user.email }}</td>
            </tr>
            <tr>
              <td>AD Account</td>
              <td>{{ user.ad_account }}</td>
            </tr>
            <tr>
              <td>Is Admin</td>
              <td>{{ user.is_admin ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <td>Roles</td>
              <td>
                <span v-if="user.roles.length === 0">
                  None Assigned
                </span>
                <span
                  v-for="role in user.roles"
                  :key="role.id"
                >
                  <span
                    v-if="role.name === 'Admin'"
                    class="badge badge-danger"
                  >
                    {{ role.name }}
                  </span>
                  <span
                    v-else-if="role.name === 'Report'"
                    class="badge badge-success"
                  >
                    {{ role.name }}
                  </span>
                  <span
                    v-else-if="role.name === 'Queue'"
                    class="badge badge-info"
                  >
                    {{ role.name }}
                  </span>
                  <span
                    v-else
                    class="badge badge-secondary"
                  >
                    {{ role.name }}
                  </span>
                  &nbsp;
                </span>
              </td>
            </tr>
          </table>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * View to show User details.
 */
import { mapActions } from 'vuex'

export default {
  props: {
    /** user id */
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      user: {
        name: '',
        email: '',
        ad_account: '',
        is_admin: false,
        roles: [],
      },
      error: '',
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      this.getUser(this.id).then(user => {
        this.user = user
      }).catch(error => {
        this.error = error.message
      })
    },
    ...mapActions('user', ['getUser']),
  },
}
</script>
