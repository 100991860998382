<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiUrl"
    :per-page="50"
    create-title="Add"
    default-sort="is_manager desc,usr.name"
    title="Team Members"
  >
    <template #roles="{ rowData }">
      <span
        v-if="rowData.is_manager"
        class="badge badge-info"
        v-text="'Manager'"
      />
      <span
        v-for="role in rowData.roles"
        :key="role"
        class="badge badge-info mr-1"
        v-text="role"
      />
    </template>
  </ListTemplate>
</template>

<script>
/**
 * View to List Teams.
 */
import ListTemplate from '@/components/ListTemplate'
import { mapActions } from 'vuex'
import teamAPI from '@/api/team'

export default {
  components: {
    ListTemplate,
  },
  props: {
    teamId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'usr.name',
          title: 'Name',
          filterType: String,
          filterField: 'usr.name_like',
        },
        {
          name: 'roles',
          title: 'Roles',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiUrl () {
      return teamAPI.teamMembersURL(this.teamId)
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      this.getTeam(this.teamId).then(team => {
        // this.team = team
      }).catch(error => {
        this.error = error.message
      })
    },
    ...mapActions('user', ['getTeam']),
  },
}
</script>
