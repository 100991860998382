<template>
  <ListTemplate
    :fields="fields"
    api-url="teams"
    :create-action="isAdmin"
    :delete-action="isAdmin"
    :update-action="isAdmin"
    :title="$route.meta.title"
  >
    <template #actions="props">
      <router-link
        v-if="canEditMembers(props.rowData)"
        :to="`${props.rowData.id}/members`"
        class="btn btn-icon btn-primary"
        tag="button"
        title="Manage Users"
        append
      >
        <AccountMultipleIcon title="Manage Users" />
      </router-link>
      <router-link
        v-if="canEditSurveyQuestiuons(props.rowData)"
        :to="`${props.rowData.id}/survey_questions`"
        class="btn btn-icon btn-primary"
        tag="button"
        title="Manage Survey Questions"
        append
      >
        <HelpCircleIcon title="Manage Survey Questions" />
      </router-link>
      <router-link
        v-if="canEditSurveyQuestiuons(props.rowData)"
        :to="`${props.rowData.id}/survey_results`"
        class="btn btn-icon btn-primary"
        tag="button"
        title="List Survey Results"
        append
      >
        <CheckCircleIcon title="List Survey Results" />
      </router-link>
    </template>
  </ListTemplate>
</template>

<script>
/**
 * View to List Teams.
 */
import ListTemplate from '@/components/ListTemplate'
import AccountMultipleIcon from 'vue-material-design-icons/AccountMultiple.vue'
import HelpCircleIcon from 'vue-material-design-icons/HelpCircle.vue'
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ListTemplate,
    AccountMultipleIcon,
    HelpCircleIcon,
    CheckCircleIcon,
  },
  data () {
    return {
      fields: [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'name',
          title: 'Name',
          filterType: String,
          filterField: 'name_like',
          sortField: 'name',
        },
        {
          name: 'descr',
          title: 'Description',
          sortField: 'descr',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin', 'isTeamManager']),
  },
  methods: {
    canEditMembers (team) {
      return this.isAdmin || this.isTeamManager(team)
    },
    canEditSurveyQuestiuons (team) {
      return (this.isAdmin || this.isTeamManager(team)) && team.enable_survey
    },
  },
}
</script>
