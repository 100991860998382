<template>
  <BaseFormCard
    :heading="submitName"
    :submit-title="submitName"
    @submit="submit"
  >
    <BaseInputDate
      v-model="holiday.date"
      title="Date"
      name="date"
      required
    />
    <BaseInput
      v-model="holiday.descr"
      title="Description"
      name="descr"
      required
      trim
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
import FieldError from '@/components/FieldError'
import holidayAPI from '@/api/holiday'

export default {
  components: {
    FieldError,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data () {
    return {
      holiday: {
        date: null,
        descr: '',
      },
      error: '',
    }
  },
  computed: {
    submitName () {
      if (!this.id) {
        return 'Create Holiday'
      }
      return 'Update Holiday'
    },
  },
  beforeMount () {
    if (this.id) {
      holidayAPI.getHoliday(this.id).then(holiday => {
        this.holiday.id = holiday.data.id
        this.holiday.date = holiday.data.date
        this.holiday.descr = holiday.data.descr
      }).catch(error => {
        this.error = error.message
      })
    }
  },
  methods: {
    submit () {
      if (this.id) {
        this.updateHoliday()
      } else {
        this.createHoliday()
      }
    },
    createHoliday () {
      this.error = ''
      holidayAPI.createHoliday(this.holiday).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    updateHoliday () {
      holidayAPI.updateHoliday(this.holiday).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
