<template>
  <BaseFormCard
    heading="Update Team"
    submit-title="Update"
    @submit="updateTeam"
  >
    <BaseInput
      v-model="team.name"
      title="Name"
      name="name"
      type="text"
      placeholder="Team Name"
      required
      trim
    />
    <BaseInput
      v-model="team.descr"
      title="Description"
      name="description"
      type="text"
      placeholder="Description"
      required
      trim
    />
    <BaseInput
      v-model="team.ldap_group_dn"
      title="LDAP Group DN"
      name="ldap_group_dn"
      type="text"
      trim
    />
    <BaseInputCheckbox
      v-model="team.enable_cr_dash"
      title="Enable CR Dashboard"
      name="enable_cr_dash"
    />
    <BaseInputCheckbox
      v-model="team.enable_survey"
      title="Enable Survey"
      name="enable_survey"
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
/**
 * View to edit a team.
 * Allows editing of members and managers.
 */
import FieldError from '@/components/FieldError'
import { mapActions } from 'vuex'
import teamAPI from '@/api/team'

export default {
  components: {
    FieldError,
  },
  props: {
    /** id for team to edit */
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      team: {
        name: '',
        descr: '',
        ldap_group_dn: '',
        enable_cr_dash: false,
        enable_survey: false,
      },
      error: '',
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      this.getTeam(this.id).then(team => {
        this.team = team
      }).catch(error => {
        this.error = error.message
      })
    },
    updateTeam () {
      const team = this.team
      teamAPI.updateTeam(team).then(resp => {
        this.$parent.$toasted.success('Team ' + team.name + ' updated')
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    ...mapActions('user', ['getTeam']),
  },
}
</script>
