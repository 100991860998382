<template>
  <ListTemplate
    v-if="resultFields.length > 0"
    ref="listTemplate"
    :fields="fields"
    :api-url="apiUrl"
    :per-page="50"
    :create-action="false"
    title="Survey Results"
    csv-download="survey.csv"
  >
    <template
      v-for="f in ratingsFieldNames"
      #[`ratings.${f}`]="{ rowData }"
    >
      <span :key="f">
        <span v-if="rowData.ratings[f] === '1'">🙁 (1)</span>
        <span v-if="rowData.ratings[f] === '3'">😐 (3)</span>
        <span v-if="rowData.ratings[f] === '5'">🙂 (5)</span>
      </span>
    </template>
  </ListTemplate>
</template>

<script>
/**
 * View to List Teams.
 */
import ListTemplate from '@/components/ListTemplate'
import { mapActions } from 'vuex'
import teamAPI from '@/api/team'
import userAPI from '@/api/user'
import formatDate from '@/filters/formatDate'

export default {
  components: {
    ListTemplate,
  },
  props: {
    teamId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      questions: [],
    }
  },
  computed: {
    apiUrl () {
      return teamAPI.teamSurveyResultsURL(this.teamId)
    },
    resultFields () {
      return this.questions.map(q => {
        return [
          {
            name: `ratings.${q.name}`,
            title: q.name,
            filterType: String,
            filterOptions: [
              { value: '1', label: '🙁 (1)' },
              { value: '3', label: '😐 (3)' },
              { value: '5', label: '🙂 (5)' },
            ],
            filterTrackBy: 'value',
            filterLabel: 'label',
          },
          {
            name: `details.${q.name}`,
            title: q.name + ' Feedback',
            visible: false,
          },
        ]
      }).flat()
    },
    ratingsFieldNames () {
      return this.questions.map(q => q.name)
    },
    fields () {
      return [
        {
          name: 'project_id',
          title: 'Project ID',
          visible: true,
          isIndex: true,
          filterType: Number,
        },
        {
          name: 'project_name',
          title: 'Project Name',
          filterType: String,
          filterField: 'project_name_like',
        },
        {
          name: 'roles.Project Manager',
          title: 'Project Manager',
          filterType: String,
          filterOptions: this.getTeamMemberNames,
        },
        {
          name: 'roles.Requester',
          title: 'Requester',
          filterType: String,
          filterOptions: userAPI.getUserNames,
        },
        {
          name: 'roles.Tech Lead',
          title: 'Tech Lead',
          filterType: String,
          filterOptions: this.getTeamMemberNames,
        },
        {
          name: 'completion_date',
          title: 'Completion Date',
          formatter: this.formatSubmitDate,
          filterType: Date,
        },
      ].concat(this.resultFields)
        .concat(
          [{
            name: 'detailLink',
            title: '',
          }],
        )
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      Promise.all([
        this.getTeam(this.teamId),
        teamAPI.getSurveyQuestions(this.teamId),
      ]).then(([team, questions]) => {
        this.questions = questions.data.filter(q => !q.disabled)
        // this.team = team
      }).catch(error => {
        this.error = error.message
      })
    },
    formatSubmitDate (value) {
      return this.formatDate(value, 'YYYY-MM-DD')
    },
    getTeamMemberNames () {
      return teamAPI.getTeamMemberNames(this.teamId)
    },
    formatDate,
    ...mapActions('user', ['getTeam']),
  },
}
</script>
