<template>
  <ListTemplate
    :fields="fields"
    :api-url="usersURL"
    title="Users"
  />
</template>

<script>
/**
 * View to list all Users
 */
import ListTemplate from '@/components/ListTemplate'
import userAPI from '@/api/user'

export default {
  components: {
    ListTemplate,
  },
  data () {
    return {
      fields: [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'name',
          title: 'Name',
          filterType: String,
          filterField: 'name_like',
          linkToIndex: true,
          sortField: 'name',
        },
        {
          name: 'email',
          title: 'E-Mail',
          filterType: String,
          filterField: 'email_like',
          sortField: 'email',
        },
        {
          name: 'ad_account',
          title: 'AD Account',
          sortField: 'ad_account',
        },
        {
          name: 'is_admin',
          title: 'Admin',
          sortField: 'is_admin',
          formatter: this.yesNo,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    usersURL () {
      return userAPI.usersURL()
    },
  },
  methods: {
    yesNo (y) {
      return y ? 'Yes' : 'No'
    },
  },
}
</script>
