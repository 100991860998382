<template>
  <BaseFormCard
    heading="Add Team Member"
    submit-title="Submit"
    @submit="createMember"
  >
    <BaseInput
      v-model="team.name"
      title="Name"
      name="name"
      type="text"
      readonly
    />
    <BaseSelect
      v-model="member.usr"
      :options="users"
      title="User"
      name="usr"
      placeholder="Select User"
      track-by="id"
      label="name"
      :loading="loading"
      required
    />
    <BaseInputCheckbox
      v-model="member.is_manager"
      title="Is Manager"
      name="is_manager"
    />
    <BaseSelect
      v-if="roles.length > 0"
      v-model="member.roles"
      :options="roles"
      title="Roles"
      name="roles"
      multiple
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
import FieldError from '@/components/FieldError'
import { mapActions } from 'vuex'
import userAPI from '@/api/user'
import teamAPI from '@/api/team'

export default {
  components: {
    FieldError,
  },
  props: {
    teamId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      team: {
        name: '',
      },
      member: {
        usr: null,
        is_manager: false,
        roles: [],
      },
      users: [],
      roles: [],
      loading: false,
      error: '',
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      Promise.all([
        this.getTeam(this.teamId),
        userAPI.getUsers(),
        userAPI.getRoles(),
      ]).then(([team, users, roles]) => {
        this.users = users.data
        this.team = team
        this.roles = roles.data.filter(r => r.is_team_role).map(r => r.project_type_name + ' : ' + r.name)
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.error = error.message
      })
    },
    createMember () {
      teamAPI.createTeamMember(this.teamId, this.member).then(resp => {
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    ...mapActions('user', ['getTeam']),
  },
}
</script>
