<template>
  <ListTemplate
    :fields="fields"
    :api-url="apiUrl"
    :per-page="50"
    create-title="Create"
    default-sort="ord"
    title="Survey Questions"
  />
</template>

<script>
/**
 * View to List Teams.
 */
import ListTemplate from '@/components/ListTemplate'
import { mapActions } from 'vuex'
import teamAPI from '@/api/team'

export default {
  components: {
    ListTemplate,
  },
  props: {
    teamId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      fields: [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'ord',
          title: 'Ord',
        },
        {
          name: 'rating',
          title: 'Rating Question',
        },
        {
          name: 'detail',
          title: 'Detail/Feedback Question',
        },
        {
          name: 'disabled',
          title: 'Disabled',
          formatter: this.yesNo,
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
        },
      ],
    }
  },
  computed: {
    apiUrl () {
      return teamAPI.teamSurveyQuestionsURL(this.teamId)
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      this.getTeam(this.teamId).then(team => {
        // this.team = team
      }).catch(error => {
        this.error = error.message
      })
    },
    yesNo (y) {
      return y ? 'Yes' : 'No'
    },
    managerRole (y) {
      return y ? 'Manager' : ''
    },
    ...mapActions('user', ['getTeam']),
  },
}
</script>
